import React, { useEffect }  from 'react'
import NavBar from '../../../Navbar/Navbar'
import { Link } from 'react-router-dom';
import './Avinashilingam.css'
import avinashilingam1 from './images/1693557975770.webp'
import avinashilingam2 from './images/recognition2.jpg'
import avinashilingam3 from './images/1693557977193.jpg'
const Avinashilingam = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
        <NavBar className="project-custom-navbar" />
      <div className="top-text">
        <div className="journal-menu"><Link to="/Journal">All,</Link> <Link to="/Thoughts">THOUGHTS,</Link> <Link to="/Recognitions">RECOGNITIONS,</Link>  <Link to="/Publications">PUBLICATIONS,</Link> <Link to="/Planning">PLANNING,</Link> <Link to="/Design">DESIGN</Link>  </div>
        <h2 className="journal">JOURNAL</h2>
      </div>
      {/* ............................................... Main Container .............................................................. */}
      <div className="container-fluid">
      <div className="inner-container-interiorexterior">
        <div className="reg-text interiorexterior-headtext">
                        <h3>AVINASHILINGAM INSTITUTE</h3>
                         <h3>18/04/23</h3>
                     </div>
        </div>
      </div>
      <div className="container-fluid">
      <div className="inner-container-avinashilingam2">
          <div className="row avinashilingam-container2-row">
            <div className="col-8 avinashilingam-container2-col1">Interactions with ID Students at AvinashiLingam</div>
            <div className="col-4 avinashilingam-container2-col2"><img src={avinashilingam1} alt="" srcset="" /></div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
      <div className="inner-container-avinashilingam3">
          <div className="row avinashilingam-container2-row">
            <div className="col-12 col-sm-12 col-md-5 avinashilingam-container3-col1"><img src={avinashilingam3} alt="" srcset="" /></div>
            <div className="col-12 col-sm-12 col-md-7 avinashilingam-container3-col2">
              
                <h2 className='mt-2 inner-head-avinashi inner-head'>AvinashiLingam INSTITUTE</h2>
                <div className="row">
                    <div className="col journal-para"><p className='mt-2'>We had the pleasure of addressing young designers at Avinashilingam University For Women Avinashilingam Institute for Home Science and Higher Education for Women this morning to usher in and inaugurate their new, restructured Interior Design program.</p>
                    <p className='mt-2'>Our principal architect Karthikeyan Padmanabhan and managing director Ruckmini Karthikeyan addressed the students about running an architectural practice and how to design with heart and soul.
                        </p></div>
                    <div className="col journal-para"><p className='mt-2'> We also highlighted our journey as architects and designers and personal anecdotes to the faculty and students.<br/>It was lovely to interact with the faculty and students and share a little about our practice. Thank you for having us.</p>
                    <p className='mt-2'>
                </p>
                </div>
                </div>
                
                <img className='mt-1 w-100' src={avinashilingam2} alt="" srcset="" />
                
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Avinashilingam