import React, { useEffect }  from 'react'
import NavBar from '../../../Navbar/Navbar'
import { Link } from 'react-router-dom';
import './Wade.css'
import Wade1 from './Images/0I5A3721.webp'
import Wade2 from './Images/0I5A3714.jpg'
import Wade3 from './Images/0I5A3712 (1).jpg'
import Wade4 from './Images/Recognition4.jpg'
const Wade = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
        <NavBar className="project-custom-navbar" />
      <div className="top-text">
        <div className="journal-menu"><Link to="/Journal">All,</Link> <Link to="/Thoughts">THOUGHTS,</Link> <Link to="/Recognitions">RECOGNITIONS,</Link>  <Link to="/Publications">PUBLICATIONS,</Link> <Link to="/Planning">PLANNING,</Link> <Link to="/Design">DESIGN</Link>  </div>
        <h2 className="journal">JOURNAL</h2>
      </div>
      {/* ............................................... Main Container .............................................................. */}
      <div className="container-fluid">
      <div className="inner-container-interiorexterior">
        <div className="reg-text interiorexterior-headtext">
                        <h3>WADE ASIA 24'</h3>
                         <h3>01/09/24</h3>
                     </div>
        </div>
      </div>
      <div className="container-fluid">
      <div className="inner-container-Wade2">
          <div className="row Wade-container2-row">
            <div className="col-8 Wade-container2-col1">WADE ASIA 24'</div>
            <div className="col-4 Wade-container2-col2"><img src={Wade1} alt="" srcset="" /></div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
      <div className="inner-container-Wade3">
          <div className="row Wade-container2-row">
            <div className="col-12 col-sm-12 col-md-5 Wade-container3-col1"><img  src={Wade4} alt="" srcset="" /></div>
            <div className="col-12 col-sm-12 col-md-7 Wade-container3-col2">
              
                <h2 className='mt-2 inner-head'>WADE ASIA 24'</h2>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-6"><p className='mt-2'>This residence was nominated for the best use of color at the WADE ASIA festival this year. While we did not win the award, we are extremely grateful for the opportunity to present the project and share a project that is so close to our hearts for so many reasons.

                        </p>
                        <p className='mt-1'>Each opportunity presents a chance to learn something about ourselves and we took away some very thoughtful insights in this experience. </p>
                        <p className='mt-1'>1. There is always room for improvement. While design is largely subjective, this experience really showed us to look at different sides and opinions. As a practice, we often don't hear criticisms on our design. ( this took us right back to the jury days in college)</p>
                        <p className='mt-2'>2. The opportunity to learn and see other architects and designers present their projects. This was the most enriching part of this conference, as we got to see so many wonderful projects that inspired us!</p>
                    <p className='mt-2'>3. We saw different ways to even present our project. While we loved the presentation we had made, it was enriching and heartening to see one designer bring the gond artisan who worked on the mural with her and have her speak on stage and share her design process.We have worked with Gond artists extensively in the Color House as well and this opened a new avenue of thinking for us.</p>
                    <p className='mt-2'>All in all, it was a great experience and we loved creating this home and it is near and dear to our hearts and the Color House being nominated was a honor. </p>
                        </div>
                    <div className="col">
                      <img className='w-100' src={Wade2} alt="" srcset="" />
                      <img className='w-100 wade-last-img' src={Wade3} alt="" srcset="" />
                    </div>
                </div>
                
                {/* <img className='mt-2' src={Wade2} alt="" srcset="" /> */}
                
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Wade