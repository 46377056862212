import React, { useEffect }  from 'react'
import NavBar from '../../../Navbar/Navbar'
import { Link } from 'react-router-dom';
import './Environment.css'
import environment1 from './Images/_F6B2166.webp'
import environment2 from './Images/H-10.webp'
import environment3 from './Images/planning3.jpg'

const Environment = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
        <NavBar className="project-custom-navbar" />
      <div className="top-text">
        <div className="journal-menu"><Link to="/Journal">All,</Link> <Link to="/Thoughts">THOUGHTS,</Link> <Link to="/Recognitions">RECOGNITIONS,</Link>  <Link to="/Publications">PUBLICATIONS,</Link> <Link to="/Planning">PLANNING,</Link> <Link to="/Design">DESIGN</Link>  </div>
        <h2 className="journal">JOURNAL</h2>
      </div>
      {/* ............................................... Main Container .............................................................. */}
      <div className="container-fluid">
      <div className="inner-container-interiorexterior">
        <div className="reg-text interiorexterior-headtext">
                        <h3>THE ENVIRONMENT</h3>
                         <h3>18/04/23</h3>
                     </div>
        </div>
      </div>
      <div className="container-fluid">
      <div className="inner-container-Artisanaldesign2">
          <div className="row Environment-container2-row">
            <div className="col-8 Environment-container2-col1">THE ENVIRONMENT</div>
            <div className="col-4 Environment-container2-col2"><img src={environment1} alt="" srcset="" /></div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
      <div className="inner-container-Artisanaldesign3">
          <div className="row Environment-container2-row">
            <div className="col-12 col-sm-12 col-md-5 Environment-container3-col1"><img src={environment2} alt="" srcset="" /></div>
            <div className="col-12 col-sm-12 col-md-7 Environment-container3-col2">
              
                <h2 className='mt-2 inner-head-avinashi inner-head'>THE ENVIRONMENT</h2>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 journal-para"><p className='mt-2'>What is the relationship between architecture and the environment?
                    </p>
                    <p className='mt-1'>There is a symbiotic, intrinsic relationship between architecture and the environment. The natural environment informs the architect about the building and it allows us to shape the space around. We need to build around the environment as opposed to forcing a building into the environment. Listening to the wisdom of a place or site is what respecting the land is.
                                        Architecture that is kind to the environment is the need of the hour, and at our practice we inspire and aspire to include the natural environment as much as possible in our designs. This is the first and primary step that we take is to ensure the inclusion of the natural environment, through landscape, interior gardens and retaining the existing landscape as much as possible. This ensures the emotional and mental wellbeing and increases the oxygen levels, leading to overall wellbeing of the users. </p>
                    <p className='mt-1'>Materiality. How can we be mindful of the usage of materiality in design and building? In vernacular architecture, we use local materials and the context of local climate to dictate the form and materiality to adapt to the surrounding environment. It is also sustainable in terms of ones carbon footprint by procuring materials that are local. While this cant be adhered to a 100% due to a number of external factors, as professionals we strive to use locally sourced materials when possible.</p>
                    <p className='mt-1'>The essence of a building in architecture. We look at the soil, the wind patterns, the sun path to design our buildings, which inform us the openings, how the flow of spaces will be, the circulation to design luxury buildings and spaces. This also shows us the placement of spaces like bathrooms where the sunlight is high and eliminating the need for artificial lighting and ventilation whenever and wherever required. It also ensures that we can design for the needs of our clientele and that we can design sustainably. </p>
                                        </div>
                    <div className="col journal-para">
                    <p className='mt-2'>Looking at the Earth as “ancestoral” and “technology” as the future. The Earth is something we've inherited, deserving of the respect and care. The Earth, every species, flora and fauna has to be revered and when we look at it as generational wealth that is passed down. When this awareness seeps in, architecture, design and construction considerably shifts and we begin to be more mindful of the impact we have on the earth. Now, as technology keeps growing and increasingly becomes an integral part of our lives, we invite you to think how we can design with more sensitivity using this technology to pay homage to our traditions and our roots. 
                  
                </p>
                <img className=' w-100' src={environment3} alt="" srcset="" /> 
                </div>
                </div>
                
                {/* */}
                
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Environment